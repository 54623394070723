main {
  padding: 50px 0;
}

.product-block {
  margin-bottom: 50px;
}

.img-logo {
  object-fit: contain;
  margin: auto;
  max-height: 40px;
  margin-right: 10px;
}

.img-product-list {
  display: block;
  object-fit: contain;
  max-height: 200px;
  margin: auto;
}

.price {
  color: #df382b;
}

.img-product {
  display: block;
  object-fit: contain;
  margin: auto;
  max-height: 400px;
  max-width: 100%;
}

.text-right {
  text-align: right;
}

.calendar {
  margin: 20px 0;
}

.date-confirmation-row {
  align-items: center;
  margin-bottom: 1rem;
}
.date-confirmation-row .alert {
  margin-bottom: 0;
}

.end-phrase {
  margin-top: 40px;
  text-align: center;
}

.end-phrase .nobuy-logo {
  height: 40px;
}

.end-phrase .brand-logo {
  height: 100px;
}
