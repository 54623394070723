body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: initial !important;
  text-decoration: none !important;
}

a * {
  color: initial;
  text-decoration: none;
}

.fc-toolbar-title {
  font-size: 1.3em !important;
}

.fc-event-title {
  color: white;
}

.fc-bg-event.fc-event {
  background-color: red !important;
}
.fc-bg-event.fc-event.fc-locked-event {
  background-color: rgb(215, 215, 215) !important;
}
.fc-locked-event .fc-event-title {
  color: black !important;
}
